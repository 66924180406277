import { Routes } from '@angular/router';
import { ShareAppComponent } from './Features/share-app/share-app.component';

export const routes: Routes = [
  { path: '', component: ShareAppComponent },
  {
    path: 'reciters/:reciterId',
    pathMatch: 'full',
    loadComponent: () =>
      import('./Features/reciter-profile/reciter-profile.component').then(
        (mod) => mod.ReciterProfileComponent
      ),
  },
  {
    path: ':category/:token',
    loadComponent: () =>
      import('./Features/share-app/share-app.component').then(
        (mod) => mod.ShareAppComponent
      ),
  },
];
